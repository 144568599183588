<template>
  <div class="system-architecture-container">
    <div class="title">产品架构</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.system-architecture-container {
  width: 1920px;
  height: 1168px;
  background: url("https://imagepub.chinau8.cn/system-ing-new.png") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  padding-top: 150px;

  .title {
    font-size: 42px;
    font-weight: bold;
    color: #333333;
    line-height: 49px;
  }
}
</style>
