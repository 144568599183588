<template>
  <div id="vital1">
    <!--头部banner部分-->
    <div class="banner">
<!--      <swiper-->
<!--          :options="swiperOption"-->
<!--          ref="mySwiper"-->
<!--          style="min-width: 1200px"-->
<!--          class="swiper_content"-->
<!--      >-->
<!--        <swiper-slide-->
<!--            class="swiper-slide swiper-container-3d"-->
<!--            v-for="(banner, index) in bannerList"-->
<!--            :key="index"-->
<!--        >-->
          <img class="banner-img" src="https://imagepub.chinau8.cn/product-bg1.png"/>
          <div class="title1 a-bounceinT">
            基于区域经济效益评价
            <br/>
            全周期管理设计
          </div>
          <div class="title2 a-bounceinL">深耕应用场景，从数字中来，到实践中去，与产业共生</div>
<!--        </swiper-slide>-->
<!--        <div class="swiper-pagination" slot="pagination"></div>-->
<!--      </swiper>-->
    </div>
    <Product></Product>

    <!--    <div class="product-system">-->
    <!--      <div class="name">产品系统</div>-->
    <!--      <div class="product-list">-->
    <!--        <div class="product-item" v-for="(item,index) in productList"-->
    <!--             :class="`${startAnimation ? 'a-fadeinL' : ''} ${'item' + index}`" v-if="startAnimation">-->
    <!--          <div class="item-title">{{ item.title }}</div>-->
    <!--          <div class="item-content">{{ item.content }}</div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="product-list">-->
    <!--        <div class="product-item" v-for="(item,index) in productList2"-->
    <!--             :class="`${(startAnimation && index <= 1) ? 'a-fadeinR' : ''} ${'item' + (index + 2)}`"-->
    <!--             v-if="startAnimation">-->
    <!--          <div class="item-title">{{ item.title }}</div>-->
    <!--          <div class="item-content">{{ item.content }}</div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="jianguan">-->
    <!--      <div class="name">核心模块</div>-->
    <!--      <div class="modele_row">-->
    <!--        <div v-for="(item,index) in moduleList" class="module_box">-->
    <!--          <div-->
    <!--              class="pic"-->
    <!--              :class="'pic' + index"-->
    <!--              :style="{ 'background-image': `url(${item.imgShadowUrl})` }"-->
    <!--          >-->
    <!--            <div class="pic_title">{{ item.title }}</div>-->
    <!--            <div v-for="desc in item.descs">{{ desc }}</div>-->
    <!--          </div>-->
    <!--          <div class="a1">-->
    <!--            <img :src="item.imgUrl"/>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <Application></Application>
    <SystemArchitecture></SystemArchitecture>

    <div class="developer">
      <div class="title">发展历程</div>
      <img src="https://imagepub.chinau8.cn/tianhua_jinhua/Group%20427318868%20%281%29.png" alt=""/>
    </div>
    <div class="bottom">
      <div class="title1">城市合伙人计划</div>
      <div class="title2">城市合伙人火热招募中</div>
      <el-button type="primary" @click="jumpPage">了解详情</el-button>
    </div>
  </div>
</template>

<script>
import SystemArchitecture from "@/views/business-a/page-a/components/system-architecture.vue";
import Product from "@/views/product/components/product.vue";
import Application from "@/views/product/components/application.vue";

export default {
  name: "product",
  components: {
    SystemArchitecture,
    Product,
    Application
  },
  data() {
    return {
      scroll: 0,
      startAnimation: false, //文字是否出现
      bannerList: [
        "https://imagepub.chinau8.cn/product-bg1.png",
      ],
      swiperOption: {
        loop: true,
        speed: 500,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        // autoplay: {
        //   delay: 4000,
        //   disableOnInteraction: false,
        // },
        autoplay: false,
        pagination: {
          el: ".swiper-pagination", //与slot="pagination"处 class 一致
          clickable: true, //轮播按钮支持点击
        },
      },
      productList: [
        {title: "数据汇交处理", content: "全面立体严密的数据交换、构建全域数据底座"},
        {title: "多维挖掘 数据复用", content: "系统数据挖掘、高效智能复用 综合分析监督建议"},
      ],
      productList2: [
        {title: "经济远行态势", content: "经济运行状况汇总分析、地企对应合并管控"},
        {title: "决策分析预警", content: "体检报告、分类模拟、重点专题、对企服务"},
      ],
      erpList: [
        {
          imgUrl: require("@/assets/product/advantage1.png"),
        },
        {
          imgUrl: require("@/assets/product/advantage2.png"),
        },
        {
          imgUrl: require("@/assets/product/advantage3.png"),
        },
        {
          imgUrl: require("@/assets/product/advantage4.png"),
        },
        {
          imgUrl: require("@/assets/product/advantage5.png"),
        },
      ],
      moduleList: [
        {
          title: "数据汇交",
          imgUrl:
              "https://imagepub.chinau8.cn/tianhua_jinhua/mj-website/module1.png",
          imgShadowUrl:
              "https://imagepub.chinau8.cn/tianhua_jinhua/mj-website/module1_shadow.png",
          descs: [
            "数据采集使用统一规范，清洗、梳理和导入，整合构建全域数据底座。",
            "保障数据安全、准确、实时。",
          ],
        },
        {
          title: "政务处理",
          imgUrl:
              "https://imagepub.chinau8.cn/tianhua_jinhua/mj-website/module2.png",
          imgShadowUrl:
              "https://imagepub.chinau8.cn/tianhua_jinhua/mj-website/module2_shadow.png",
          descs: [
            "构建政务数据共享交换机制，实现交换数据全程追溯；",
            "解决了涉及业务参与方多、数据交换流程长、审批环节复杂等问题。",
          ],
        },
        {
          title: "经济运行态势",
          imgUrl:
              "https://imagepub.chinau8.cn/tianhua_jinhua/mj-website/module3.png",
          imgShadowUrl:
              "https://imagepub.chinau8.cn/tianhua_jinhua/mj-website/module3_shadow.png",
          descs: [
            "为企业用户提供专业的操作工作台； ",
            "为职能部门提供以企业为维度的信息管理体系，对经济运行状况汇总分析。",
          ],
        },
        {
          title: "多维运算",
          imgUrl:
              "https://imagepub.chinau8.cn/tianhua_jinhua/mj-website/module6.png",
          imgShadowUrl:
              "https://imagepub.chinau8.cn/tianhua_jinhua/mj-website/module6_shadow.png",
          descs: [
            "灵活配置，自动化流程，最大限度满足精准施策；",
            "定制设置与二次开发相互独立，方便管理维护。",
          ],
        },
        {
          title: "产业全景图",
          imgUrl:
              require('@/assets/product/icon1.png'),
          imgShadowUrl:
              "https://imagepub.chinau8.cn/tianhua_jinhua/mj-website/module5_shadow.png",
          descs: [
            "对区域整体工业企业运行态势综合管理和监控，精准掌握区域内经济运行情况；",
            "助力摸清工业经济“家底”",
          ],
        },
        {
          title: "决策分析预警",
          imgUrl:
              require('@/assets/product/icon2.png'),
          imgShadowUrl:
              "https://imagepub.chinau8.cn/tianhua_jinhua/mj-website/module4_shadow.png",
          descs: ["构建助力政府数智水平提升的综合性服务大数据亚台，数据支撑精准施策赋能治理手段。"],
        },
      ],
    };
  },
  created() {
    console.log(this.$route.query)
    if (this.$route.query.active) {
      setTimeout(() => {
        window.scrollTo({top: 1370, behavior: "smooth"})
      }, 100)
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  watch: {
    "$route.query.active": {
      handler(newVal) {
        console.log(newVal)
      },
      immediate: true
    },
  },
  methods: {
    handleScroll() {
      //或者使用document.querySelector('.class或者#id').scrollTop
      this.scroll =
          document.documentElement.scrollTop || document.body.scrollTop;
      this.startAnimation = this.scroll > 250;
    },
    jumpPage() {
      this.$router.push({path: 'partner'});
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("firstTrue");
    next();
    // 离开当前组件时候触发
  },
};
</script>

<style lang="scss" scoped>
.a-bounceinL {
  animation: bounceinL 1.5s ease-out backwards;
  animation-delay: .5s;
}

.a-bounceinT {
  animation: bounceinT 1.5s ease-out backwards;
  animation-delay: .5s;
}

.a-fadeinL {
  animation: fadeinL 1s ease-out backwards;
}

.a-fadeinR {
  animation: fadeinR 1s ease-out backwards;
}


.banner {
  width: 1920px;
  height: 734px;
  background: #fff;
  position: relative;

  .banner-img {
    width: 1920px;
    height: 734px;
    z-index: -99;
  }


  .title1 {
    width: 900px;
    position: absolute;
    top: 239px;
    left: 16%;
    z-index: 999;
    font-size: 64px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 5px;
    line-height: 98px;
    text-align: left;
  }

  .title2 {
    width: 1000px;
    position: absolute;
    top: 450px;
    left: 16%;
    z-index: 999;
    font-size: 36px;
    color: #fff;
    letter-spacing: 3px;
    line-height: 140px;
    text-align: left;
  }

  .title3 {
    width: 1316px;
    position: absolute;
    top: 450px;
    left: 50%;
    margin-left: -658px;
    z-index: 999;
    font-size: 64px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 5px;
    line-height: 140px;
  }
}

.name {
  height: 54px;
  font-size: 36px;
  font-weight: bold;
  color: #333333;
  line-height: 42px;
  width: 100%;
  text-align: center;
}

.subTitle {
  font-size: 24px;
  font-weight: 500;
  color: #333333;
  line-height: 28px;
  margin-top: 15px;
}

.white {
  color: white;
}

.desc {
  width: 100%;
  height: 36px;
  font-size: 24px;
  font-weight: 500;
  color: #666666;
  line-height: 28px;
  margin-top: 20px;
}

.product-system {
  width: 1920px;
  height: 678px;
  background: url("https://imagepub.chinau8.cn/product-sysyem-bg.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 60px;
  box-sizing: border-box;
  position: relative;

  .product-list {
    .product-item {

    }

    .item-title {
      font-size: 24px;
      font-weight: bold;
      color: #066BF1;
      line-height: 47px;
    }

    .item-content {
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      line-height: 40px;
    }

    .item0 {
      position: absolute;
      top: 34%;
      left: 23.5%;

      .item-content {
        margin-left: -100px;
      }
    }

    .item1 {
      position: absolute;
      top: 34%;
      left: 55%;

      .item-content {
        //width: 260px;
        text-align: left;
        padding-left: 60px;
      }
    }

    .item2 {
      position: absolute;
      top: 52%;
      left: 17%;
    }

    .item3 {
      position: absolute;
      top: 61%;
      left: 64%;
    }

  }
}

.jianguan {
  height: 1080px;
  background: url("https://imagepub.chinau8.cn/tianhua_jinhua/mj-website/module.png") no-repeat center center;
  background-size: 100% 100%;
  padding: 70px 260px 0px 260px;
  box-sizing: border-box;
}

.modele_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .module_box {
    width: 370px;
    height: 370px;
    background: linear-gradient(
            225deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(255, 255, 255, 0.08) 100%
    );
    opacity: 1;
    margin-top: 60px;
    position: relative;

    img {
      width: 370px;
      height: 370px;
    }

    .pic {
      position: absolute;
      width: 370px;
      height: 370px;
      background: rgba(6, 107, 241, 0.6);
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 75px 36px;
      font-size: 20px;
      font-weight: 300;
      color: #ffffff;
      line-height: 34px;
      text-align: left;
      text-indent: 40px;

      .pic_title {
        font-size: 36px;
        font-weight: bold;
        color: #ffffff;
        line-height: 42px;
        margin-bottom: 15px;
        text-align: center;
        text-indent: 0;
      }
    }

    .pic0 {
      padding: 75px 30px;
    }

    .a1 {
      position: absolute;
      top: 0;
      width: 370px;
      height: 370px;
      overflow: hidden;
      transition: all 0.3s;
    }

    .a1:hover {
      // transform: translateY(-6px);
      box-shadow: 0 26px 40px -24px rgba(0, 36, 100, 0.5);
      opacity: 0;
      // animation: move01 2s linear forwards;
    }

    @keyframes move01 {
      1% {
        opacity: 1;
      }
      25% {
        transform: rotateY(0);
      }
      62% {
        opacity: 1;
      }
      62.5% {
        transform: rotateY(90deg);
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
  }
}

.module_box:hover .pic {
  // transform: translateY(-6px);
  box-shadow: 0 26px 40px -24px rgba(0, 36, 100, 0.5);
  opacity: 1;
  // animation: move02 1.5s 0.5s linear forwards;
  @keyframes move02 {
    48% {
      opacity: 0;
    }
    49% {
      opacity: 1;
    }
    50% {
      transform: rotateY(90deg);
    }
    100% {
      transform: rotateY(18deg);
      opacity: 1;
    }
  }
}

.developer {
  height: 1220px;
  //background: url("https://imagepub.chinau8.cn/Group%20427318868.png") no-repeat center center;
  background-size: 100% 100%;
  padding: 60px 280px;
  //box-sizing: border-box;
  .title {
    font-size: 36px;
    font-weight: bold;
    color: #333333;
    line-height: 42px;
    margin-bottom: 60px;
  }

  img {
    width: 100%;
    margin-bottom: 50px;
    //height: 1071px;
  }
}

.bottom {
  width: 1920px;
  height: 644px;
  background: url("https://imagepub.chinau8.cn/partner-bg.png") no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  position: relative;

  .title1 {
    width: 1000px;
    position: absolute;
    top: 75px;
    left: 50%;
    margin-left: -458px;
    z-index: 999;
    font-size: 128px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 12px;
    line-height: 256px;
  }

  .title2 {
    width: 786px;
    position: absolute;
    top: 312px;
    left: 50%;
    margin-left: -393px;
    z-index: 999;
    font-size: 64px;
    font-weight: 400;
    color: #fff;
    letter-spacing: 6px;
    line-height: 128px;
  }

  ::v-deep {
    .el-button {
      width: 187px;
      height: 70px;
      position: absolute;
      top: 465px;
      left: 50%;
      margin-left: -93px;
      z-index: 999;
      background: linear-gradient(45deg, #217EFF 0%, #124FED 100%);
      border-radius: 8px;
      font-size: 28px;
      letter-spacing: 4px;
      font-weight: bold;
      color: #FFFFFF;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {

}
</style>
