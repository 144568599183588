<template>
  <div class="container">
    <div class="flex">
      <img src="~@/assets/product/product.png" alt=""/>
      <div class="right">
        <div class="title">产品系统</div>
        <div class="content">
          系统结合以大数据技术为核心，多业务板块并存的设计理念，整合横向部门共享数据、
          纵向企业填报数据，建设集数据分级共享、企业分类分档、科学评价测算、
          多维分析展示、综合决策应用于一体的亩均效益综合评价大数据平台。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.container {
  width: 1920px;
  height: 706px;
  box-sizing: border-box;
  padding: 58px 260px 110px;
  overflow: hidden;

  img {
    width: 538px;
    height: 533px;
    margin-right: 65px;
  }

  .right {
    text-align: left;
    margin-top: 79px;

    .title {
      font-size: 36px;
      font-weight: bold;
      color: #333333;
      line-height: 42px;
      margin-bottom: 49px;
      padding-left: 10px;
    }

    .content {
      font-size: 26px;
      font-weight: 400;
      color: #333333;
      line-height: 40px;
      text-indent: 2em;
    }

  }
}
</style>
