<template>
  <div class="container">
    <div class="title a-fadeinB">产品应用</div>
    <div class="box flex  a-fadeinB">
      <div class="left">
        <div class="tab" v-for="(item,index) in tabList" :class="activeTab == index ? 'active' : ''"
             @click="tabChange(item,index)">
          {{ item.title }}
        </div>
      </div>
      <div class="right">
        <img v-if="activeTab == 0" src="~@/assets/product/tab1.png" alt=""/>
        <img v-if="activeTab == 1" src="~@/assets/product/tab2.png" alt=""/>
        <img v-if="activeTab == 2" src="~@/assets/product/tab3.png" alt=""/>
        <img v-if="activeTab == 3" src="~@/assets/product/tab4.png" alt=""/>
        <img v-if="activeTab == 4" src="~@/assets/product/tab5.png" alt=""/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: this.$route.query.active || 0,
      tabList: [
        {title: "企业信息库", value: 0},
        {title: "年度评价名单", value: 1},
        {title: "综合评价计算", value: 2},
        {title: "年度绩效统计", value: 3},
        {title: "综合分析运用", value: 4},
      ]
    }
  },
  methods: {
    tabChange(item, index) {
      this.activeTab = index
    }
  },
  created() {
    document.addEventListener('contextmenu', function (e) {
      e.preventDefault();
    });
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 1920px;
  height: 960px;
  box-sizing: border-box;
  background: #F5F7FC;
  padding: 120px 230px;
  position: relative;

  .title {
    font-size: 36px;
    font-weight: bold;
    color: #333333;
    line-height: 61px;
    margin-bottom: 80px;
  }

  .box {
    .left {
      .tab {
        width: 220px;
        height: 114px;
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        line-height: 114px;
        border-bottom: 1px solid #ccc;
        margin-right: 114px;
        cursor: pointer;
        text-align: left;

        &.active {
          color: #0153D7;
          font-weight: bold;
        }
      }
    }

    .right {
      img {
        width: 100%;
        height: 635px;
      }
    }
  }
  img {
    -webkit-user-drag: none;
    user-select: none;
  }
}
</style>
